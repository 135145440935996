import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'headerBurgerMenuIcon', 'headerOffCanvasMenu', 'headerBurgerMenuIconClose' ]

  toggle() {
    document.querySelector('.header__burger-menu-links').style.height = window.innerHeight + 'px';
    this.headerBurgerMenuIconTarget.classList.toggle('header__burger-menu-icons--open');
    this.headerOffCanvasMenuTarget.classList.toggle('header__burger-menu-links--open');
    this.headerBurgerMenuIconCloseTarget.classList.toggle('header__burger-menu-icon-close--open');
    document.body.classList.toggle('overflow-hidden');
  }

  connect() {
    document.querySelector('.header__burger-menu-links').style.height = window.innerHeight + 'px';
  }
}

// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import CheckboxSelectAll from "stimulus-checkbox-select-all"
import CharacterCounter from "stimulus-character-counter"
import TextareaAutogrow from "stimulus-textarea-autogrow"
import { Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"

const application = Application.start()
// const context = require.context("controllers", true, /_controller\.js$/)
// application.load(definitionsFromContext(context))

// CheckboxSelectAll
application.register("checkbox-select-all", CheckboxSelectAll)

// CharacterCounter
application.register("character-counter", CharacterCounter)

// TextareaAutogrow
application.register("textarea-autogrow", TextareaAutogrow)

// Import and register all TailwindCSS Components
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)


// Custom stimulus controllers
import AmazonController from './amazon_controller'
application.register('amazon', AmazonController)

import FilterMenuController from './filter_menu_controller'
application.register('filter-menu', FilterMenuController)

import HeaderController from './header_controller'
application.register('header', HeaderController)

import SearchMenuController from './search_menu_controller'
application.register('search-menu', SearchMenuController)


// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "./controllers"

import ahoy from "ahoy.js"

ahoy.configure({
  cookies: false
});

document.addEventListener("turbolinks:load", function() {
  var element = document.querySelector("#tracking-data")
  if (element != undefined) {
    let event = element.dataset.ahoyEvent;
    ahoy.track(`${event}`, element.dataset);
  }
});

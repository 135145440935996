import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'menu' ]

  toggle() {
    document.querySelector('.filter-menu').style.height = window.innerHeight + 'px';
    this.menuTarget.classList.toggle('filter-menu--visible');
    document.body.classList.toggle('overflow-hidden');
  }

  hide() {
    this.menuTarget.classList.remove('filter-menu--visible');
    document.body.classList.remove('overflow-hidden');
  }


  connect() {

  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'sourceUrl', 'amazonAsin', 'externalUrl', 'externalTitle', 'title', 'price', 'priceCurrency', 'savings', 'savingsCurrency', 'externalImageUrl', 'externalImagePreview', 'brand', 'externalFeatures', 'externalProductGroup' ]

  extractProductId() {
    let productId,
        regex,
        matches,
        urlString = this.sourceUrlTarget.value;

    if (typeof(urlString) !== 'undefined' && typeof(urlString) === 'string') {
        regex = /(?:[/dp/]|$)([A-Z0-9]{10})/;
        matches = urlString.match(regex);
        productId = matches[1];

        return productId;
    }

    return false;
  }

  fetchProduct = function(productId) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', '/a/amazon_api/' + productId);
        xhr.onload = () => resolve(xhr.responseText);
        xhr.onerror = () => reject(xhr.statusText);
        xhr.send();
    });
  };


  fetch() {
    const productId = this.extractProductId();

    this.fetchProduct(productId).then((data) => {
      console.log(JSON.parse(data));
      this.populateInputs(JSON.parse(data));
    }).catch((data) => {
        alert(data);
    });
  }

  populateInputs(responseDate) {
    console.log(responseDate);
    this.amazonAsinTarget.value = responseDate['amazon_asin']
    this.externalImageUrlTarget.value = responseDate['external_image_url']
    this.externalImagePreviewTarget.src = responseDate['external_image_url']
    this.externalTitleTarget.value = responseDate['external_title']
    this.externalUrlTarget.value = responseDate['detail_page_url']
    this.priceCurrencyTarget.value = responseDate['price_currency']
    this.priceTarget.value = responseDate['price']
    this.savingsCurrencyTarget.value = responseDate['savings_currency']
    this.savingsTarget.value = responseDate['savings']
    this.titleTarget.value = responseDate['external_title']
    this.brandTarget.value = responseDate['brand']
    this.externalFeaturesTarget.value = responseDate['features']
    this.externalProductGroupTarget.value = responseDate['product_group']

    // TODO: Large Image URL
  }

  connect() {

  }
}

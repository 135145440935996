import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'container' ]

  toggle(e) {
    e.preventDefault();
    this.containerTarget.classList.toggle('search-menu__entry--open');
  }

  connect() {

  }
}
